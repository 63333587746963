<template>
	<div v-if="$screen.width <= 992" class="animated fadeIn">
		<div class="container-mobile" v-if="myProject">
			<div class="my-dashboard-mobile">
				<div class="row">
					<b-col :cols="6">
						<div class="title">
							<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
								{{ FormMSG(1, 'Welcome back') }}, <span v-if="$has(myProfile)">{{ myProfile.firstName }}</span
								>.
							</h1>
						</div>
					</b-col>

					<b-col
						v-if="canSeeProd"
						:cols="$screen.width <= 576 ? 12 : 6"
						class="row text-right"
						:style="{
							'align-items': 'center',
							'justify-content': 'end'
						}"
					>
						<b-form-radio v-model="selectedDashboard" class="mr-3" name="some-radios" :value="0">
							<div style="margin-top: 3px">{{ FormMSG(25201, 'Personal') }}</div>
						</b-form-radio>
						<b-form-radio v-model="selectedDashboard" name="some-radios" :value="1">
							<div style="margin-top: 3px">{{ FormMSG(26531, 'Production') }}</div>
						</b-form-radio>
					</b-col>
				</div>
				<div class="onboarding-actions" v-if="myProject && (myProject.useTimesheet || myProject.useExpense || myProject.usePurchaseOrder)">
					<div class="d-flex justify-content-center wapper">
						<router-link
							to="/mytimesheets"
							class="btn-action gap-right"
							tag="div"
							id="action-add-workday"
							v-if="myProject && myProject.useTimesheet"
						>
							<img src="svgs/timesheet.svg" alt="Timesheet" />
							<span>{{ FormMSG(29, 'Workday') }}</span>
							<b-tooltip target="action-add-workday" placement="bottom">
								{{ FormMSG(30, 'Add Workday') }}
							</b-tooltip>
						</router-link>
						<router-link to="/myexpenses" class="btn-action gap-right" tag="div" id="action-add-expense" v-if="myProject && myProject.useExpense">
							<img src="svgs/expense.svg" alt="Expense" />
							<span>{{ FormMSG(31, 'Expense') }}</span>
							<b-tooltip target="action-add-expense" placement="bottom">
								{{ FormMSG(32, 'Add Expense') }}
							</b-tooltip>
						</router-link>
						<router-link
							to="/mypo"
							class="btn-action gap-right"
							tag="div"
							id="action-add-po"
							v-if="myProject && myProject.usePurchaseOrder && !isFilmSingle"
						>
							<!-- <img  src="svgs/profile.svg" alt="Profile"/> -->
							<img src="svgs/po.svg" alt="Purshase order" />
							<span>{{ FormMSG(33, 'Purchase Order') }}</span>
							<b-tooltip target="action-add-po" placement="bottom">
								{{ FormMSG(34, 'Add Purchase Order') }}
							</b-tooltip>
						</router-link>
						<router-link to="/contacts" class="btn-action" tag="div" id="action-contact" v-if="isDeterProject === true">
							<img src="svgs/profile.svg" alt="Contact" />
							<span>{{ FormMSG(105, 'Contact') }}</span>
							<b-tooltip target="action-contact" placement="bottom">{{ FormMSG(105, 'Contact') }}</b-tooltip>
						</router-link>
						<div class="btn-action" id="action-share-profile" @click="openShareSubscriptionModal" v-if="isFilmSingle">
							<img src="svgs/profile.svg" alt="Share Profile" />
							<span>{{ FormMSG(51, 'Share Profile') }}</span>
							<b-tooltip target="action-share-profile" placement="bottom">
								{{ FormMSG(55, 'Share Profile') }}
							</b-tooltip>
						</div>
					</div>
				</div>
				<ShareInformation
					ref="modalShareSubscriptionSolo"
					:btn-label="FormMSG(169, 'Share')"
					:title-modal="FormMSG(170, 'Send your personal informations sheet')"
					:msg-success="FormMSG(250, 'Profile successfully sent')"
					send-a-copy
					hide-button
				/>
				<div v-show="selectedDashboard == 0" class="content">
					<div class="header d-flex pb-1 align-items-center">
						<div class="project-title">{{ myProject ? myProject.titre : '' }}</div>
						<router-link to="/myproject" class="switch-label ml-auto" tag="div">
							<div class="d-flex justify-content-end align-items-center pr-1">
								<refresh-cw :size="16" id="switch-to-another-project" /><span class="pl-1">{{ FormMSG(56, 'Switch') }}</span>
							</div>
						</router-link>
					</div>
					<div class="grid-project-resume">
						<router-link
							to="/mytimesheets"
							:class="`block-resume${isNoneBorderProjectResume(1) ? ' none-border' : ''}`"
							tag="div"
							v-if="myProject && myProject.useTimesheet"
						>
							<div class="amount" v-if="canSeeSalary">{{ formattedKPIS.salaries }}</div>
							<div class="amount" v-else></div>
							<div class="label">{{ FormMSG(36, 'Salaries') }}</div>
							<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
						</router-link>
						<router-link
							to="/myexpenses"
							:class="`block-resume${isNoneBorderProjectResume(2) ? ' none-border' : ''}`"
							tag="div"
							v-if="myProject && myProject.useExpense"
						>
							<div class="amount">{{ formattedKPIS.expenses }}</div>
							<div class="label">{{ FormMSG(37, 'Expenses') }}</div>
							<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
						</router-link>
						<router-link
							to="/mypo"
							:class="`block-resume${isNoneBorderProjectResume(3) ? ' none-border' : ''}`"
							tag="div"
							v-if="myProject && myProject.usePurchaseOrder && !isFilmSingle"
						>
							<div class="amount">{{ formattedKPIS.pos }}</div>
							<div class="label">{{ FormMSG(38, 'Purchase Orders') }}</div>
							<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
						</router-link>
					</div>
					<div class="notifications" v-if="!isFilmSingle">
						<div class="header d-flex pt-3 pb-2">
							<div class="project-title w-100">{{ FormMSG(39, 'My Notifications') }}</div>
						</div>
						<div class="grid-project-resume">
							<div v-if="(totalNotification > 0 && $screen.width > 576) || (totalNotification - totalDocToSign > 0 && $screen.width <= 576)">
								<router-link
									to="/profile?forDocs=true"
									:class="`block-resume${isNoneBorderNotification(1) ? ' none-border' : ''}`"
									v-if="totalDocToRead > 0"
									tag="div"
								>
									<div class="label d-flex w-95">
										<div class="notification">
											<b-badge>{{ totalDocToRead }}</b-badge>
										</div>
										<div class="type">{{ FormMSG(52, 'New Documents') }}</div>
									</div>
									<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
								</router-link>
								<router-link
									:to="`${isDocManager ? '/project/users' : '/profile?forDocs=true'}`"
									:class="`block-resume${isNoneBorderNotification(2) ? ' none-border' : ''}`"
									v-if="totalDocToSign > 0 && $screen.width > 576"
									tag="div"
								>
									<div class="label d-flex w-95">
										<div class="notification">
											<b-badge>{{ totalDocToSign }}</b-badge>
										</div>
										<div class="type">{{ FormMSG(167, 'Documents to validate/sign') }}</div>
									</div>
									<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
								</router-link>
								<router-link
									to="/myaccomodations"
									:class="`block-resume${isNoneBorderNotification(3) ? ' none-border' : ''}`"
									v-if="totalAccToRead > 0"
									tag="div"
								>
									<div class="label d-flex w-95">
										<div class="notification">
											<b-badge>{{ totalAccToRead }}</b-badge>
										</div>
										<div class="type">{{ FormMSG(53, 'New Accomodations') }}</div>
									</div>
									<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
								</router-link>
								<div
									@click="handleClick('mytimesheets')"
									:class="`block-resume${isNoneBorderNotification(4) ? ' none-border' : ''}`"
									v-if="myProject && myProject.useTimesheet && totalTsToValidate > 0"
									tag="div"
								>
									<div class="label d-flex w-95">
										<div class="notification">
											<b-badge>{{ totalTsToValidate }}</b-badge>
										</div>
										<div class="type">{{ FormMSG(40, 'Timesheets to validate') }}</div>
									</div>
									<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
								</div>
								<div
									@click="handleClick('myexpense')"
									:class="`block-resume${isNoneBorderNotification(5) ? ' none-border' : ''}`"
									v-if="myProject && myProject.useExpense && totalExpToValidate > 0"
									tag="div"
								>
									<div class="label d-flex w-95">
										<div class="notification">
											<b-badge>{{ totalExpToValidate }}</b-badge>
										</div>
										<div class="type">{{ FormMSG(42, 'Expenses to validate') }}</div>
									</div>
									<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
								</div>
								<div
									@click="handleClick('mypo')"
									:class="`block-resume${isNoneBorderNotification(6) ? ' none-border' : ''}`"
									v-if="myProject && myProject.usePurchaseOrder && totalPoToValidate > 0"
									tag="div"
								>
									<div class="label d-flex w-95">
										<div class="notification">
											<b-badge>{{ totalPoToValidate }}</b-badge>
										</div>
										<div class="type">{{ FormMSG(43, 'Purchase orders to validate') }}</div>
									</div>
									<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
								</div>
								<router-link
									to="/mytimesheets"
									:class="`block-resume${isNoneBorderNotification(7) ? ' none-border' : ''}`"
									v-if="totalTsRefused > 0"
									tag="div"
								>
									<div class="label d-flex w-95">
										<div class="notification">
											<b-badge>{{ totalTsRefused }}</b-badge>
										</div>
										<div class="type">{{ FormMSG(60, 'Timesheets refused') }}</div>
									</div>
									<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
								</router-link>
								<router-link
									to="/mytimesheets"
									:class="`block-resume${isNoneBorderNotification(8) ? ' none-border' : ''}`"
									v-if="totalExpRefused > 0"
									tag="div"
								>
									<div class="label d-flex w-95">
										<div class="notification">
											<b-badge>{{ totalExpRefused }}</b-badge>
										</div>
										<div class="type">{{ FormMSG(61, 'Expenses refused') }}</div>
									</div>
									<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
								</router-link>
								<router-link
									to="/mypo"
									:class="`block-resume${isNoneBorderNotification(9) ? ' none-border' : ''}`"
									v-if="totalPoRefused > 0"
									tag="div"
								>
									<div class="label d-flex w-95">
										<div class="notification">
											<b-badge>{{ totalPoRefused }}</b-badge>
										</div>
										<div class="type">{{ FormMSG(62, 'Purchase orders refused') }}</div>
									</div>
									<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
								</router-link>
								<router-link
									to="/mytimesheets"
									:class="`block-resume${isNoneBorderNotification(10) ? ' none-border' : ''}`"
									v-if="totalExpItemRefused > 0"
									tag="div"
								>
									<div class="label d-flex w-95">
										<div class="notification">
											<b-badge>{{ totalExpItemRefused }}</b-badge>
										</div>
										<div class="type">{{ FormMSG(64, 'Expense items refused') }}</div>
									</div>
									<div class="icon"><component :is="getLucideIcon('ChevronRight')" color="#06263E" :size="22" /></div>
								</router-link>
							</div>
							<div v-else>
								<div class="block-resume none-border">
									<div class="label w-100">
										{{ FormMSG(54, 'Everything has been put away, sorted and classified.') }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="documents" v-if="myProject && myProject.useDocument && !isFilmSingle">
						<div class="header d-flex pt-3 pb-2">
							<div class="project-title">{{ FormMSG(44, 'My Documents') }}</div>
							<router-link to="/profile?forDocs=true" class="switch-label ml-auto" tag="div">{{ FormMSG(45, 'View all documents') }}</router-link>
						</div>
						<div class="documents-resume">
							<div class="document-list w-100">
								<!-- TODO remove "w-100" when add btn is uncommented -->
								<ul v-if="documents.length > 0">
									<li v-for="Doc in documents.slice(0, 3)" :key="Doc.id + randKeys()">
										<!-- :data-tail="`${Doc.fileName.substring(Doc.fileName.lastIndexOf('.') -2 )}`" -->
										<span class="icon"><component :is="getLucideIcon('FileText')" :size="20" /></span>
										<span class="filename">{{ Doc.name }}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="carbon-footprint-container">
						<carbon-dashboard level="user" />
					</div>
				</div>
				<div v-show="selectedDashboard == 1" class="content">
					<div class="carbon-footprint-container">
						<carbon-dashboard />
						<project-kpis />
						<budget-dashboard />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="animated fadeIn" style="font-size: 1rem">
		<div class="row">
			<b-col :cols="6">
				<div class="title">
					<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
						{{ FormMSG(1, 'Welcome back') }}, <span v-if="$has(myProfile)">{{ myProfile.firstName }}</span
						>.
					</h1>
				</div>
			</b-col>

			<b-col
				v-if="canSeeProd"
				:cols="6"
				class="row text-right"
				:style="{
					'align-items': 'center',
					'justify-content': 'end'
				}"
			>
				<b-form-radio v-model="selectedDashboard" class="mr-3" name="some-radios" :value="0">
					<div style="margin: 0">{{ FormMSG(25201, 'Personal') }}</div>
				</b-form-radio>
				<b-form-radio v-model="selectedDashboard" name="some-radios" :value="1">
					<div style="margin: 0">{{ FormMSG(26531, 'Production') }}</div>
				</b-form-radio>
			</b-col>
		</div>
		<b-row v-if="myProject && (myProject.useTimesheet || myProject.useExpense || myProject.usePurchaseOrder)">
			<b-col>
				<b-card id="onboarding-top-btns" class="card-border-blue-light">
					<b-card-body>
						<b-row class="d-flex justify-content-center">
							<b-col sm="4" v-if="myProject && myProject.useTimesheet">
								<b-button
									block
									variant="outline-primary"
									size="md"
									href="#"
									to="/mytimesheets"
									style="font-size: 1.25rem"
									class="d-flex justify-content-center align-items-center"
								>
									<component :is="getLucideIcon('CalendarDays')" :size="22" />
									<div class="ml-3">{{ FormMSG(26, 'Add Timesheet') }}</div>
								</b-button>
							</b-col>
							<b-col sm="4" v-if="myProject && myProject.useExpense">
								<b-button
									block
									variant="outline-primary"
									size="md"
									href="#"
									to="/myexpenses"
									style="font-size: 1.25rem"
									class="d-flex justify-content-center align-items-center"
								>
									<component :is="getLucideIcon('Wallet')" :size="22" />
									<div class="ml-3">{{ FormMSG(27, 'Add Expense') }}</div>
								</b-button>
							</b-col>
							<b-col sm="4" v-if="myProject && myProject.usePurchaseOrder && !isFilmSingle">
								<b-button
									block
									variant="outline-primary"
									size="md"
									href="#"
									to="/mypo"
									style="font-size: 1.25rem"
									class="last d-flex justify-content-center align-items-center"
								>
									<component :is="getLucideIcon('ShoppingCart')" :size="22" />
									<div class="ml-3">{{ FormMSG(28, 'Add Purchase Order') }}</div>
								</b-button>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
		<b-row v-show="selectedDashboard == 0">
			<b-col md="8">
				<div class="clearfix d-flex align-items-center">
					<h2 id="active-project" class="block-sm w-50">{{ this.getActiveProjectLabel() }}</h2>
					<b-link to="/myproject" class="text-uppercase mt-2 block-sm w-50 text-right mr-1">{{ this.getSwitchProjectLabel() }}</b-link>
				</div>
				<b-card v-if="myProject !== null" class="card-border-blue-light">
					<!-- <b-card> -->
					<b-card-body>
						<b-row>
							<b-col md="4">
								<h4 class="text-left">
									<b>{{ myProject ? myProject.titre : '' }}</b>
								</h4>
								<img v-bind:src="this.imageName" loading="lazy" class="project-picture-preview" alt="" />
							</b-col>
							<b-col md="8" class="font-color">
								<div class="clearfix border-bottom p-2" v-if="myProject && myProject.useTimesheet">
									<b>{{ FormMSG(4, 'Salaries') }}</b> <br />
									<span class="text-primary" v-if="canSeeSalary">{{ formattedKPIS.salaries }}</span>
									<b-link to="/mytimesheets" class="text-uppercase float-right">{{ FormMSG(5, 'View salaries') }}</b-link>
								</div>
								<div class="clearfix border-bottom p-2" v-if="myProject && myProject.useExpense">
									<b>{{ FormMSG(6, 'Expenses') }}</b> <br />
									<span class="text-primary">{{ formattedKPIS.expenses }}</span>
									<b-link to="/myexpenses" class="text-uppercase float-right">{{ FormMSG(7, 'View expenses') }}</b-link>
								</div>
								<div class="clearfix p-2" v-if="myProject && myProject.usePurchaseOrder && !isFilmSingle">
									<b>{{ FormMSG(8, 'P.O.') }}</b> <br />
									<span class="text-primary">{{ formattedKPIS.pos }}</span>
									<b-link to="/mypo" class="text-uppercase float-right">{{ FormMSG(9, 'View P.O.') }}</b-link>
								</div>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
			<b-col md="4">
				<h2 v-if="!isFilmSingle">{{ FormMSG(10, "What's next") }}</h2>
				<b-card class="card-border-blue-light">
					<b-card-body>
						<b-row v-if="isFilmSingle">
							<b-col>
								<div class="custom-card">
									<div class="custom-card-body text-center mb-5">
										<p>{{ FormMSG(41, 'MY PROFILE') }}</p>
									</div>
									<div class="custom-card-footer text-center">
										<router-link to="/profile" tag="b-button" class="mr-2">
											{{ FormMSG(168, 'View') }}
										</router-link>
										<ShareInformation
											:btn-label="FormMSG(169, 'Share')"
											:title-modal="FormMSG(170, 'Send your personal informations sheet')"
											:msg-success="FormMSG(250, 'Profile successfully sent')"
											send-a-copy
										/>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
		<b-row v-if="!isFilmSingle && myProject && myProject.useDocument && selectedDashboard == 0">
			<b-col md="6" class="onboarding-notifications">
				<h2>{{ FormMSG(11, 'My notifications') }}</h2>
				<b-card class="card-border-blue-light">
					<b-card-body class="font-color">
						<div v-if="(totalNotification > 0 && $screen.width > 576) || (totalNotification - totalDocToSign > 0 && $screen.width <= 576)">
							<div class="clearfix border-bottom p-2 d-flex" style="gap: 7px" v-if="totalDocToRead > 0">
								<div>
									<b-badge pill class="badge-variant">
										<span class="badge-notification">{{ totalDocToRead }}</span>
									</b-badge>
								</div>
								<b class="flex-grow-1">{{ FormMSG(12, 'new documents') }}</b>
								<b-link to="/profile?forDocs=true" class="text-uppercase float-right">{{ FormMSG(13, 'View') }}</b-link>
							</div>
							<div class="clearfix border-bottom p-2 d-flex" style="gap: 7px" v-if="totalDocToSign > 0 && $screen.width > 576">
								<div>
									<b-badge pill class="badge-variant">
										<span class="badge-notification">{{ totalDocToSign }}</span>
									</b-badge>
								</div>
								<b class="flex-grow-1">{{ FormMSG(171, 'documents to validate/sign') }}</b>
								<b-link :to="`${isDocManager ? '/project/users' : '/profile?forDocs=true'}`" class="text-uppercase float-right">{{
									FormMSG(13, 'View')
								}}</b-link>
							</div>
							<div class="clearfix border-bottom p-2 d-flex" style="gap: 7px" v-if="totalAccToRead > 0">
								<div>
									<b-badge pill class="badge-variant">
										<span class="badge-notification">{{ totalAccToRead }}</span>
									</b-badge>
								</div>
								<b class="flex-grow-1">{{ FormMSG(14, 'new accomodations') }}</b>
								<b-link to="/myaccomodations" class="text-uppercase float-right">{{ FormMSG(13, 'View') }}</b-link>
							</div>
							<div class="clearfix border-bottom p-2 d-flex" style="gap: 7px" v-if="myProject && myProject.useTimesheet && totalTsToValidate > 0">
								<div>
									<b-badge pill class="badge-variant">
										<span class="badge-notification">{{ totalTsToValidate }}</span>
									</b-badge>
								</div>
								<b class="flex-grow-1">{{ FormMSG(15, 'timesheets to validate') }}</b>
								<b-link to="/mytimesheets?validate=true" class="text-uppercase float-right">{{ FormMSG(13, 'View') }}</b-link>
							</div>
							<div class="clearfix border-bottom p-2 d-flex" style="gap: 7px" v-if="myProject && myProject.useExpense && totalExpToValidate > 0">
								<div>
									<b-badge pill class="badge-variant">
										<span class="badge-notification">{{ totalExpToValidate }}</span>
									</b-badge>
								</div>
								<b class="flex-grow-1">{{ FormMSG(16, 'expenses to validate') }}</b>
								<b-link to="/myexpenses?validate=true" class="text-uppercase float-right">{{ FormMSG(13, 'View') }}</b-link>
							</div>
							<div
								class="clearfix border-bottom p-2 d-flex"
								style="gap: 7px"
								v-if="myProject && myProject.usePurchaseOrder && totalPoToValidate > 0"
							>
								<div>
									<b-badge pill class="badge-variant">
										<span class="badge-notification">{{ totalPoToValidate }}</span>
									</b-badge>
								</div>
								<b class="flex-grow-1">{{ FormMSG(17, 'purchase orders to validate') }}</b>
								<b-link to="/mypo?validate=true" class="text-uppercase float-right">{{ FormMSG(13, 'View') }}</b-link>
							</div>
							<div class="clearfix border-bottom p-2 d-flex" style="gap: 7px" v-if="totalTsRefused > 0">
								<div>
									<b-badge pill class="badge-variant">
										<span class="badge-notification">{{ totalTsRefused }}</span>
									</b-badge>
								</div>
								<b class="flex-grow-1">{{ FormMSG(57, 'timesheets refused') }}</b>
								<b-link to="/mytimesheets" class="text-uppercase float-right">{{ FormMSG(13, 'View') }}</b-link>
							</div>
							<div class="clearfix border-bottom p-2 d-flex" style="gap: 7px" v-if="totalExpRefused > 0">
								<div>
									<b-badge pill class="badge-variant">
										<span class="badge-notification">{{ totalExpRefused }}</span>
									</b-badge>
								</div>
								<b class="flex-grow-1">{{ FormMSG(58, 'expenses refused') }}</b>
								<b-link to="/myexpenses" class="text-uppercase float-right">{{ FormMSG(13, 'View') }}</b-link>
							</div>
							<div class="clearfix border-bottom p-2 d-flex" style="gap: 7px" v-if="totalPoRefused > 0">
								<div>
									<b-badge pill class="badge-variant">
										<span class="badge-notification">{{ totalPoRefused }}</span>
									</b-badge>
								</div>
								<b class="flex-grow-1">{{ FormMSG(59, 'purchase orders refused') }}</b>
								<b-link to="/mypo" class="text-uppercase float-right">{{ FormMSG(13, 'View') }}</b-link>
							</div>
							<div class="clearfix border-bottom p-2 d-flex" style="gap: 7px" v-if="totalExpItemRefused > 0">
								<div>
									<b-badge pill class="badge-variant">
										<span class="badge-notification">{{ totalExpItemRefused }}</span>
									</b-badge>
								</div>
								<b class="flex-grow-1">{{ FormMSG(63, 'expense items refused') }}</b>
								<b-link to="/myexpenses" class="text-uppercase float-right">{{ FormMSG(13, 'View') }}</b-link>
							</div>
						</div>
						<div v-else class="empty-state">
							<span>{{ FormMSG(18, 'Everything has been put away, sorted and classified!') }}</span>
						</div>
					</b-card-body>
				</b-card>
			</b-col>
			<b-col md="6" class="onboarding-documents" v-if="myProject && myProject.useDocument && !isFilmSingle">
				<div class="clearfix">
					<h2 class="pull-left block-sm">{{ FormMSG(20, 'My documents') }}</h2>
				</div>
				<b-card class="card-border-blue-light">
					<b-card-body>
						<div v-if="documents.length > 0">
							<div v-for="document in documents" :key="document.id + randKeys()" class="border-bottom clearfix d-flex align-items-center">
								<div class="w-75">
									<!-- <pre>{{ document }}</pre> -->
									<div style="color: rgba(6, 38, 62, 0.74)">{{ document.name }}</div>
									<div class="d-flex flex-row align-items-center">
										<div class="text-primary mt-0">{{ document.type !== 11 ? document.fileName : FormMSG(104, 'Document Package') }}</div>
										<div class="ml-2">
											<div class="wrap-status">
												<div :class="`status ${classDocumentStatus(document.status)}`" style="font-size: 0.7rem">
													{{ validatedStatus(document.status) }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<b-link to="/profile?forDocs=true" class="text-uppercase w-25 text-right">{{ FormMSG(13, 'View') }}</b-link>
							</div>
						</div>
						<div v-else class="empty-state">
							<span>{{ FormMSG(180, 'You have no document yet!') }}</span>
						</div>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
		<b-row v-show="selectedDashboard == 0">
			<b-col md="12" class="dashboard-embed">
				<carbon-dashboard level="user" class="font-color" />
				<evolution-dashboard level="user" />
			</b-col>
		</b-row>
		<b-row v-show="selectedDashboard == 1">
			<b-col md="12" class="dashboard-embed">
				<carbon-dashboard />
				<project-kpis />
				<budget-dashboard />
			</b-col>
		</b-row>
	</div>
</template>

<script>
import BudgetDashboard from './dashboard/BudgetDashboard';
import ProjectKpis from './dashboard/ProjectKPIs';
import CarbonDashboard from './dashboard/CarbonDashboard';
import EvolutionDashboard from './dashboard/EvolutionDashboard';
import languageMessages from '@/mixins/languageMessages';
// import gql from 'graphql-tag';
// import Vue from "vue";
import { store } from '@/store';
import { isNil } from '~utils';
import numeral from 'numeral';
import { myProfile } from '@/cruds/users.crud';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import ShareInformation from '@/components/InfoSheet/ShareInformation';
import { RefreshCw } from 'lucide-vue';
// import { getNotifications } from "@/cruds/header.crud";
import { mapGetters, mapActions } from 'vuex';
// import { getDocumentList } from '@/modules/document-package/cruds/document.crud';
import globalMixin from '@/mixins/global.mixin';
import { myProject } from '@/cruds/project.crud';
import { getUserDashboard } from '@/cruds/users.crud';
import DeterMixin from '@/mixins/deter.mixin';
import { classDocumentStatus } from '@/shared/global-status';

export default {
	name: 'OnboardingHome',
	components: {
		CarbonDashboard,
		EvolutionDashboard,
		ShareInformation,
		RefreshCw,
		ProjectKpis,
		BudgetDashboard
	},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin, DeterMixin],
	props: {
		level: {
			type: String
		}
	},
	async created() {
		await this.loadProfile();
		this.myProject = await this.getMyProject();
		this.userDashboard = await this.getUserDashboard();
	},
	async mounted() {
		this.selectedDashboard = 0;
		this.getDocuments({
			userId: +store.userID(),
			orderBy: 1,
			limit: 3,
			showOnlyNewDocument: true
		});
	},
	data: function () {
		return {
			erreur: {},
			myProfile: {
				id: 0
			},
			selectedDashboard: 0,
			mainKpis: {},
			popSwitchToAnotherProject: false,
			myProject: [],
			userDashboard: {},
			classDocumentStatus
		};
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications',
			getDocuments: 'document/getDocuments'
		}),
		randKeys() {
			const characters = 'abcdefghijklmnopqrstuvwxyz';
			let result = '';
			for (let i = 0; i < 5; i++) {
				result += characters.charAt(Math.floor(Math.random() * characters.length));
			}
			return result;
		},
		async loadProfile() {
			this.myProfile = await myProfile();
			//console.log("in loadProfile Onboarding vue:",this.myProfile);
		},
		getSwitchProjectLabel() {
			if (store.isPme()) {
				return this.FormMSG(102, 'Switch to another Legal Entity');
			}
			return this.FormMSG(2, 'Switch to another project');
		},
		getActiveProjectLabel() {
			if (store.isPme()) {
				return this.FormMSG(103, 'Active Legal Entity');
			}
			return this.FormMSG(3, 'Active project');
		},
		handleClick(screen) {
			if (this.$screen.width <= 992) {
				this.createToastForMobile(
					this.FormMSG(2001, 'Not allowed'),
					this.FormMSG(
						2311,
						"It's currrently not possible to do approval from a mobile device. To access the approval screens, please use a computer."
					),
					'',
					'danger',
					true
				);
			} else {
				this.$router.push(`/${screen}/?validate=true`);
			}
		},
		isNoneBorderNotification(number) {
			switch (number) {
				case 1:
					return (
						this.myProject &&
						this.totalAccToRead === 0 &&
						this.totalAccToSign === 0 &&
						this.myProject.useTimesheet &&
						this.totalTsToValidate === 0 &&
						this.myProject.useExpense &&
						this.totalExpToValidate === 0 &&
						this.myProject.usePurchaseOrder &&
						this.totalPoToValidate === 0
					);
				case 2:
					return (
						this.myProject &&
						this.totalDocToRead > 0 &&
						this.totalAccToSign === 0 &&
						this.myProject.useTimesheet &&
						this.totalTsToValidate === 0 &&
						this.myProject.useExpense &&
						this.totalExpToValidate === 0 &&
						this.myProject.usePurchaseOrder &&
						this.totalPoToValidate === 0
					);
				case 3:
					return (
						this.myProject &&
						this.totalAccToSign > 0 &&
						this.myProject.useTimesheet &&
						this.totalTsToValidate === 0 &&
						this.myProject.useExpense &&
						this.totalExpToValidate === 0 &&
						this.myProject.usePurchaseOrder &&
						this.totalPoToValidate === 0
					);
				case 4:
					return (
						this.myProject.useTimesheet &&
						this.totalTsToValidate > 0 &&
						this.myProject.useExpense &&
						this.totalExpToValidate === 0 &&
						this.myProject.usePurchaseOrder &&
						this.totalPoToValidate === 0
					);
				case 5:
					return this.myProject.useExpense && this.totalExpToValidate > 0 && this.myProject.usePurchaseOrder && this.totalPoToValidate === 0;
				case 6:
					return (
						this.myProject.usePurchaseOrder &&
						this.totalPoToValidate > 0 &&
						this.totalTsRefused === 0 &&
						this.totalPoRefused === 0 &&
						this.totalExpRefused === 0
					);
				case 7:
					return this.totalTsRefused > 0 && this.totalPoRefused === 0 && this.totalExpRefused === 0 && this.totalExpItemRefused === 0;
				case 8:
					return this.totalPoRefused > 0 && this.totalExpRefused === 0 && this.totalExpItemRefused === 0;
				case 9:
					return this.totalExpRefused > 0 && this.totalExpItemRefused === 0;
				case 10:
					return this.totalExpItemRefused > 0;
			}
		},
		isNoneBorderProjectResume(number) {
			switch (number) {
				case 1:
					return this.myProject && !this.myProject.useExpense && !(this.myProject.usePurchaseOrder && !this.isFilmSingle);
				case 2:
					return this.myProject && !(this.myProject.usePurchaseOrder && !this.isFilmSingle);
				case 3:
					return true;
			}
		},
		openShareSubscriptionModal() {
			this.$refs['modalShareSubscriptionSolo'].openModal();
		},
		openSwitchToAnotherProject() {
			this.popSwitchToAnotherProject = !this.popSwitchToAnotherProject;
		},
		async getMyProject() {
			return await myProject();
		},
		async getUserDashboard() {
			return await getUserDashboard();
		},
		validatedStatus(value) {
			const statuses = this.FormMenu(1012);
			const status = statuses.filter((status) => status.value === value);

			return status.length > 0 ? status[0].text : '';
		}
	},
	computed: {
		canSeeSalary() {
			return !store.getCurrentProjectConfig().hideSalaryInfo || store.canViewGlobalBudget();
		},
		canSeeProd() {
			return store.canViewGlobalBudget();
		},
		...mapGetters({
			totalNotification: 'header/getTotalNotification',
			totalDocToRead: 'header/getTotalDocToRead',
			totalDocToSign: 'header/getTotalDocToSign',
			totalAccToRead: 'header/getTotalAccToRead',
			totalTsToValidate: 'header/getTotalTsToValidate',
			totalPoToValidate: 'header/getTotalPoToValidate',
			totalExpToValidate: 'header/getTotalExpToValidate',
			totalTsRefused: 'header/getTotalTsRefused',
			totalPoRefused: 'header/getTotalPoRefused',
			totalExpRefused: 'header/getTotalExpRefused',
			totalExpItemRefused: 'header/getTotalExpItemRefused',
			documents: 'document/getAll'
		}),
		imageName() {
			if (!isNil(this.myProject) && this.myProject.picture && this.myProject.picture.length > 0) {
				var strImg = process.env.VUE_APP_GQL + '/images/' + this.myProject.picture;
				//  console.log("pictPath:",strImg);
				return strImg;
			} else {
				return 'templatedata/avatar.jpg';
			}
		},
		formattedKPIS() {
			const currencySymbol = (store && store.state && store.state.myProfile && store.state.myProfile.currencySymbol) || '€';
			if (isNil(this.userDashboard) || (!isNil(this.userDashboard) && Object.keys(this.userDashboard).length === 0)) {
				return {};
			}
			return {
				expenses: `${currencySymbol} ${numeral(this.userDashboard.myTotalExpenses).format('0,0.00')}`,
				pos: `${currencySymbol} ${numeral(this.userDashboard.myTotalPurchaseOrders).format('0,0.00')}`,
				salaries: `${currencySymbol} ${numeral(this.userDashboard.myTotalSalary).format('0,0.00')}`
			};
		},
		isDocManager() {
			if (
				(store.state.myProfile.upm ||
					store.state.myProfile.gateKeeper ||
					store.state.myProfile.productionAccountant ||
					store.state.myProfile.lineProducer ||
					store.state.myProfile.studio ||
					store.state.myProfile.canValidateI9) &&
				store.getCurrentProjectConfig().useDocument
			) {
				return true;
			}

			return false;
		}
	}
};
</script>

<style lang="scss">
.border-bottom {
	border-bottom: 0.005rem solid rgba(226, 230, 235, 1) !important;
}
.onboarding-notifications,
.onboarding-documents {
	.border-bottom:last-child {
		border: none !important;
	}
}

.onboarding-documents .border-bottom {
	padding: 10px 0;
	&:last-child {
		padding-bottom: 0;
	}
}

.dashboard-embed .container-fluid {
	padding: 0;
}

.project-picture-preview {
	margin: 1rem auto;
	width: 200px;
	max-width: 100%;
	height: auto;
	//object-fit: cover;
}

@media (max-width: 576px) {
	#onboarding-top-btns .btn {
		margin-bottom: 1rem;
	}

	#onboarding-top-btns .btn.last {
		margin-bottom: 0;
	}
}
.custom-card {
	border-radius: 5px;
	border: 1px solid #e4dbdb;
	padding: 20px;
}

.font-color {
	color: rgba(6, 38, 62, 0.74) !important;
}

.badge-notification {
	font-size: 1rem;
	font-weight: 700;
}
</style>
